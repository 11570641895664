export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [4,[2]],
		"/(auth)/login": [11,[3]],
		"/(app)/movies": [7,[2]],
		"/(app)/movies/category/[category]": [9,[2]],
		"/(app)/movies/[id]": [8,[2]],
		"/(auth)/register": [12,[3]],
		"/(app)/search": [10,[2]],
		"/(app)/[username]": [5,[2]],
		"/(app)/[username]/list/[list_id]": [6,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';